import * as dompack from "dompack";
import * as mso from "@mod-mso/webdesigns/mso/mso";
import * as whintegration from "@mod-system/js/wh/integration";
import "./fysiotwente.scss";
import "./components/header/header.scss";

import "./widgets/carousel/carousel";
import "./components/header/header";

dompack.onDomReady(() => {
  new mso.MSOSite();
});
