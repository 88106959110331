/* global AccessibleMenu, Mmenu, $ */

import * as dompack from "dompack";
import * as whintegration from "@mod-system/js/wh/integration";

dompack.onDomReady(() => {
  // get custom menu and button
  const cm = document.querySelector("#custommenu");
  const cmToggle = document.querySelector("#toggle-menu");

  if (!cm || !cmToggle) return;

  var cmToggled = false;

  // toggles the mobile menu
  const toggleMenu = (e) => {
    cmToggled = !cmToggled;
    if (cmToggled) {
      // Add classes
      cm.classList.add("is-visible");
      document.body.classList.add("menu-open");
      cm.setAttribute("aria-hidden", "false");
      cmToggle.setAttribute("aria-expanded", "true");
    } else {
      // Remove classes
      cm.classList.remove("is-visible");
      document.body.classList.remove("menu-open");
      cm.setAttribute("aria-hidden", "true");
      cmToggle.setAttribute("aria-expanded", "false");
    }
    cmToggled
      ? cmToggle.classList.add("is-active")
      : cmToggle.classList.remove("is-active");
  };

  // Close menu when a link is clicked
  const menuLinks = cm.querySelectorAll(".custommenu__menulink");
  menuLinks.forEach((link) => {
    link.addEventListener("click", () => {
      // Only close if this is a direct link (no dropdown)
      if (!link.classList.contains("custommenu__dropdown--toggle")) {
        cmToggled = false;
        cm.classList.remove("is-visible");
        document.body.classList.remove("menu-open");
        cmToggle.classList.remove("is-active");
        cm.setAttribute("aria-hidden", "true");
        cmToggle.setAttribute("aria-expanded", "false");
      }
    });
  });

  // Close menu when clicking outside of menu content
  cm.addEventListener("click", (e) => {
    // Check if the click is directly on the menu backdrop (not on menu items)
    if (e.target === cm) {
      cmToggled = false;
      cm.classList.remove("is-visible");
      document.body.classList.remove("menu-open");
      cmToggle.classList.remove("is-active");
      cm.setAttribute("aria-hidden", "true");
      cmToggle.setAttribute("aria-expanded", "false");
    }
  });

  // Handle escape key press to close menu
  document.addEventListener("keydown", (e) => {
    if (e.key === "Escape" && cmToggled) {
      cmToggled = false;
      cm.classList.remove("is-visible");
      document.body.classList.remove("menu-open");
      cmToggle.classList.remove("is-active");
      cm.setAttribute("aria-hidden", "true");
      cmToggle.setAttribute("aria-expanded", "false");
    }
  });

  // Get the scrollable column instead of the whole menu
  const scrollableColumn = cm.querySelector(".custommenu__col--1");

  // Prevent touchmove event propagation on scrollable column when at boundaries
  // to prevent iOS Safari from scrolling the body behind
  scrollableColumn.addEventListener("touchstart", function (e) {
    const touchY = e.touches[0].clientY;

    const handleTouchMove = function (e) {
      const currentY = e.touches[0].clientY;
      const element = scrollableColumn;

      // Check if at top boundary and trying to scroll up
      if (element.scrollTop <= 0 && currentY > touchY) {
        e.preventDefault();
      }

      // Check if at bottom boundary and trying to scroll down
      else if (
        element.scrollHeight - element.scrollTop <= element.clientHeight + 1 &&
        currentY < touchY
      ) {
        e.preventDefault();
      }
    };

    scrollableColumn.addEventListener("touchmove", handleTouchMove, {
      passive: false,
    });

    // Clean up the touchmove handler after touchend
    const cleanup = function () {
      scrollableColumn.removeEventListener("touchmove", handleTouchMove);
      scrollableColumn.removeEventListener("touchend", cleanup);
      scrollableColumn.removeEventListener("touchcancel", cleanup);
    };

    scrollableColumn.addEventListener("touchend", cleanup);
    scrollableColumn.addEventListener("touchcancel", cleanup);
  });

  // Add touch events for better mobile experience
  cmToggle.addEventListener("touchend", (e) => {
    e.preventDefault(); // Prevent default touch behavior
    toggleMenu();
  });

  // Handle window resize events to ensure proper menu behavior
  let resizeTimeout;
  window.addEventListener("resize", () => {
    // Debounce the resize event
    clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(() => {
      // If menu is open and window is resized beyond mobile breakpoint, close it
      if (cmToggled && window.innerWidth > 768) {
        cmToggled = false;
        cm.classList.remove("is-visible");
        document.body.classList.remove("menu-open");
        cmToggle.classList.remove("is-active");
        cm.setAttribute("aria-hidden", "true");
        cmToggle.setAttribute("aria-expanded", "false");
      }
    }, 250);
  });

  cmToggle.addEventListener("click", toggleMenu);
});
