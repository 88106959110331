/* global $ */
import "./carousel.scss";
import * as dompack from "dompack";

dompack.onDomReady(() => {
  const carousels = dompack.qSA(".w-carousel");
  carousels.forEach(initCarousel);

  // Add event listener for window resize
  window.addEventListener(
    "resize",
    debounce(() => {
      carousels.forEach(initCarousel); // Reinitialize carousels on resize
    }, 250)
  ); // Adjust debounce time as needed
});

function initCarousel(carousel) {
  const carouselRow = carousel.querySelector(".w-carousel__cols");
  const ctaWidth = document.querySelector(".w-carousel__col").offsetWidth;
  fillRow(carouselRow);

  const carouselChildren = [...carouselRow.children];
  let isDragging = false;
  let dragCheck = false;
  let startX, startScrollLeft;
  const delta = 6; // Sensitivity for dragging
  let href, imglink;

  // Start dragging
  const dragStart = (e) => {
    if (e.button !== 0) return; // Only handle left click

    isDragging = true;
    startX = e.pageX;
    startScrollLeft = carouselRow.scrollLeft;
    carouselRow.classList.add("dragging");

    imglink = e.target.closest(".w-carousel__imagelink") || e.target;
    href = imglink.href;
  };

  // Stop dragging
  const dragStop = () => {
    isDragging = false;
    carouselRow.classList.remove("dragging");
    setTimeout(() => {
      if (imglink) imglink.setAttribute("href", href);
      dragCheck = false;
    }, 300);
  };

  // Handle dragging
  const dragging = (e) => {
    if (!isDragging) return;
    carouselRow.scrollLeft = startScrollLeft - (e.pageX - startX);
    if (Math.abs(e.pageX - startX) > delta) {
      imglink.removeAttribute("href");
      dragCheck = true;
    }
  };

  // Snap to beginning or end based on scroll position
  const infiniteScroll = () => {
    const scrollLeft = carouselRow.scrollLeft;
    const scrollWidth = carouselRow.scrollWidth;
    const offsetWidth = carouselRow.offsetWidth;

    if (scrollLeft === 0) {
      carouselRow.classList.add("no-smoothing");
      carouselRow.scrollLeft = scrollWidth - 2 * offsetWidth;
      carouselRow.classList.remove("no-smoothing");
    } else if (Math.ceil(scrollLeft) >= scrollWidth - offsetWidth) {
      carouselRow.classList.add("no-smoothing");
      carouselRow.scrollLeft = offsetWidth;
      carouselRow.classList.remove("no-smoothing");
    }
  };

  // Adjust initial scroll position to allow for proper seamless scrolling
  const ctasPerView = Math.round(carouselRow.offsetWidth / ctaWidth);

  // Clear out any previously cloned items to avoid duplication
  carouselRow.innerHTML = "";

  // Add a full view (amount) of CTAs before and after for seamless snapping
  carouselChildren
    .slice(-ctasPerView)
    .reverse()
    .forEach((cta) => {
      carouselRow.insertAdjacentHTML("afterbegin", cta.outerHTML);
    });
  carouselChildren.forEach((cta) => {
    carouselRow.insertAdjacentHTML("beforeend", cta.outerHTML);
  });
  carouselChildren.slice(0, ctasPerView).forEach((cta) => {
    carouselRow.insertAdjacentHTML("beforeend", cta.outerHTML);
  });

  // Set the initial scroll position to the first real CTA (skipping the clones)
  carouselRow.scrollLeft = ctasPerView * ctaWidth;

  // Align to the nearest item on button click
  const alignToNearestItem = (scrollOffset) => {
    const scrollLeft = carouselRow.scrollLeft;
    const ctaWidth = document.querySelector(".w-carousel__col").offsetWidth;
    const currentIndex = Math.round(scrollLeft / ctaWidth);
    carouselRow.scrollLeft = (currentIndex + scrollOffset) * ctaWidth;
  };

  // Button actions
  const actions = carousel.querySelectorAll(".w-carousel__action");
  actions.forEach((btn) => {
    btn.addEventListener("click", () => {
      if (btn.id === "prev") {
        carouselRow.scrollLeft -= ctaWidth;
        setTimeout(() => alignToNearestItem(-1), 100); // Align to the previous item
      } else {
        carouselRow.scrollLeft += ctaWidth;
        setTimeout(() => alignToNearestItem(1), 100); // Align to the next item
      }
    });
  });

  // Dragging listeners
  carouselRow.addEventListener("mousemove", dragging);
  carouselRow.addEventListener("mousedown", dragStart);
  document.addEventListener("mouseup", dragStop);
  carouselRow.addEventListener("scroll", infiniteScroll);
}

// Create and display popup
function popupHandler(el, check) {
  if (check) return;

  const popupBackdrop = document.createElement("div");
  const popup = document.createElement("div");
  const content = el.firstChild.cloneNode(true);
  const backButtonContainer = document.createElement("div");
  const backButton = document.createElement("div");

  backButtonContainer.appendChild(backButton);
  backButton.setAttribute("class", "w-carousel__popup--item");
  backButton.innerHTML =
    '<svg id="popup-backbutton" style="cursor: pointer;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>window-close</title><path d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z" /></svg>';
  backButton.addEventListener("click", closePopupHandler);

  popup.appendChild(backButtonContainer);
  backButtonContainer.setAttribute(
    "class",
    "w-carousel__popup--btncontainer w-carousel__popup--item"
  );

  popup.appendChild(content);
  content.setAttribute("class", "w-carousel__popup--content");
  content.setAttribute("style", "display: block;");

  popupBackdrop.appendChild(popup);
  popup.setAttribute("class", "w-carousel__popup w-carousel__popup--item");
  popup.addEventListener("click", (e) => e.stopPropagation());

  document.body.appendChild(popupBackdrop);
  popupBackdrop.setAttribute(
    "class",
    "w-carousel__popup--backdrop w-carousel__popup--item"
  );
  popupBackdrop.addEventListener("click", closePopupHandler);
}

// Remove all popup elements
function closePopupHandler() {
  document
    .querySelectorAll(".w-carousel__popup--item")
    .forEach((popup) => popup.remove());
}

// Toggle visibility of hover content on mobile
function toggleHandler(el) {
  const content = el.querySelector(".w-carousel__hover--content");
  content.style.maxHeight =
    content.style.maxHeight !== "300px" ? "300px" : "0px";
}

// Fill row with additional clones to create a seamless scrolling effect
function fillRow(el) {
  const children = [...el.children];
  const viewAmount = Math.round(el.offsetWidth / el.firstChild.offsetWidth);

  // Add clones until the view amount is filled
  for (let i = children.length; i < viewAmount; i += children.length) {
    children.forEach((child) => {
      el.appendChild(child.cloneNode(true));
    });
  }
}

// Debounce function to limit the rate at which a function is executed
function debounce(func, wait) {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}
